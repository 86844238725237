import React from "react";
import * as mainStyle from "../styles/styles";
import Container from "react-bootstrap/Container";
import Logo from "../images/logo/logo2.svg";
import BackToTop from 'react-back-to-top-button';
import {DisclaimerComponent} from "../components/Disclaimer/disclaimer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {Footer} from "../components/Footer/footer"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/global.css";
import "../styles/toggler.css";
import {QesNavbar} from "../components/Navbar/navbar"

export default function Disclaimer() {

    return (
        <div>
            <main style={mainStyle.pageStyles}>
                <title>Quant Edge Solutions | Disclaimer</title>
                <QesNavbar/>
                <link rel="icon" type="image/x-icon" href={Logo}/>

                <BackToTop
                    showAt={50}
                    speed={1500}
                    easing="easeInOutQuint"
                >
                    <ArrowUpwardIcon sx={{color: '#203B72'}}/>
                </BackToTop>
            </main>
            <Container>
                <DisclaimerComponent/>
                <Footer/>
            </Container>
        </div>
    )
}

